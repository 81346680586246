
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Home';
import Events from './Events';
import EventShell from './EventShell';
import CreateEvent from './CreateEvent';
import Profile from './Profile';
import TestCreateEvent from './TestCreateEvent';
import LoadingSpinner from './LoadingSpinner';
import './styles.css';
import ScrollToTop from './ScrollToTop';
import ErrorPage from './ErrorPage';



function App() {
    const [loading, setLoading] = useState(false);
    const location = useLocation();

    useEffect(() => {
        
        const handleRouteChange = () => {
            setLoading(true);
            const timer = setTimeout(() => setLoading(false), 1000); 
            return () => clearTimeout(timer);
        };

        handleRouteChange();
    }, [location]);

    return (
        <>
            {/* {loading && <LoadingSpinner loading={loading} />} */}
            <ScrollToTop /> {/* Ensure this is rendered above your Routes */}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/events" element={<Events />} />
                <Route path="/event/:id" element={<EventShell />} />
                <Route path="/createEvent" element={<CreateEvent />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/error" element={<ErrorPage />} />
                {/* <Route path="/createEvent" element={<TestCreateEvent />} /> */}
            </Routes>
        </>
    );
}


const AppWithRouter = () => (
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

export default AppWithRouter;
