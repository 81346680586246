import React, { useState } from "react";
import { ReactComponent as SuccessIcon } from "./SuccessIcon.svg";
import { ReactComponent as CloseIcon } from './CloseIcon.svg';
import { useNavigate } from 'react-router-dom'; // For React Router
import { information } from './information';
import axios from "axios";

const secrets = require("./secrets.js");
const api = secrets.server_url;


function EditRSVP(props) {

    return (props.trigger) ? (
        <>
            <div class="registration-modal-wrapper">
                <div class="registration-modal">
                    <div class="title-icon">
                        <p class="rm-title"> Update Your RSVP </p>
                        
                        <CloseIcon className="close" onClick={() => props.setTrigger(false)} />
                    </div>

                    <p class="rm-subtext cancel-subtext"> Let us know if your plans have changed. Click the button below to update your status.</p>

                    <button class = "next-step-registration cancel-button" onClick={async () => {
                        // window.location.reload()
                        await props.handleUnRegister(props.id)
                        window.location.reload()
                        }}> Cancel Registration </button>


                </div>
            </div>
        </>
    ) : "";
}

export default EditRSVP;