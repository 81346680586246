const AWS = require('aws-sdk');
const secrets = require('../secrets')

// Configure AWS SDK
// Configure AWS SDK
AWS.config.update({
    accessKeyId: secrets.s3_acces_key_id, // Replace with your actual access key ID
    secretAccessKey: secrets.s3_secretAccessKey, // Replace with your actual secret access key
    region: 'us-east-2'
  });

const s3 = new AWS.S3();

export const uploadImage = async (file) => {
    const params = {
      Bucket: 'ummahfy-event-images', // Replace with your bucket name
      Key: `${Date.now()}-${file.name}`, // Generate a unique key for the file
      Body: file,
      ContentType: file.type
    };
  
    try {
      const data = await s3.upload(params).promise();
      //// console.log('Successfully uploaded image:', data.Location);
      return data.Location; // This is the URL you will save in the database
    } catch (err) {
      console.error('Error uploading image:', err);
      throw err;
    }
};


// Example usage
// const filePath = '/Users/bilalyousuf/Desktop/Ummahfy/backend/dataCollection/uploads/1722208960951-2024-07-06_21-26-36_UTC.jpg';
// uploadImage(filePath).then(imageUrl => {
//   if (imageUrl) {
//     // Save imageUrl to your database
//     //// console.log('Image URL to save:', imageUrl);
//     // saveImageUrl(imageUrl); // Your function to save the URL to the database
//   }
// });