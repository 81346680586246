import { loggedInUser, loggedInEmail } from "./information.js";
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Signin from './Signin';
import SignUp from './SignUp';
import axios from 'axios';

import KuficLogo from './KuficLogo.png';
import WebsiteHeader from './WebsiteHeader.png';
import NavbarLogo from './NavbarLogo.svg';


const secrets = require('./secrets.js');
const api = secrets.server_url;

//instead of use effect use conditional render

// const fetchSessionData = async () => {
//     try {
//         const response = await axios.get(`${api}getLoggedInUser/`, {
//             withCredentials: true // Include cookies with requests
//         });
//         return response.data;
//     } catch (error) {
//         console.error("Error fetching session data:", error);
//         return null;
//     }
// };

const logout = async () => {
    try {
        const response = await axios.get(`${api}logout`, {
            withCredentials: true // Include cookies with requests
        });


        if (response.status === 200 && response.data.data) {
            window.location.href = '/'; // Redirect to the home page on successful logout
        } else {
            console.error('Logout failed:', response.data.message);
            alert('Failed to logout');
        }
    } catch (error) {
        console.error('Error during logout:', error);
        alert('An error occurred during logout');
    }
};

const Navbar = () => {
    const [buttonSignin, setButtonSignin] = useState(false);
    const [buttonSignUp, setButtonSignUp] = useState(false);
    const [showMenu, setShowMenu] = useState(window.innerWidth <= 890 ? false : true);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [user, setUser] = useState(loggedInUser);

    // useEffect(() => {

    //     setUser(loggedInUser);

    // }, []);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const closeDrawer = () => {
        setIsDrawerOpen(false);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 890) {
                setShowMenu(false);
            } else {
                setShowMenu(true);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);


    return (
        <div>
            <div className="navbar">
                <div className="item1">
                    <Link to="/" style={{ padding: '0', margin: '0', cursor: 'pointer' }}>
                        <img src={NavbarLogo} alt="navbar-logo" style={{ height: '30px', marginTop: '8px', cursor: 'pointer' }} />
                    </Link>
                </div>

                {showMenu ? (
                    <div className="item3">
                        <ul>
                            <li>
                                <Link to="/events">
                                    <button className="navb find-events-nav">Find Events</button>
                                </Link>
                            </li>
                            {user && user.data ? (
                                <>
                                    <li>
                                        <Link to="/createEvent">
                                            <button className="navb create-event-nav">Create Event</button>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/profile">
                                            <button className="navb create-event-nav">Profile</button>
                                        </Link>
                                    </li>
                                    <li>
                                        <button className="navb log-out" onClick={logout}>Log Out</button>
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li>
                                        <button className="navb login" onClick={() => setButtonSignin(true)}>Log In</button>
                                        <Signin trigger={buttonSignin} setTrigger={setButtonSignin} />
                                    </li>
                                    <li>
                                        <button className="navb signup-nav" onClick={() => setButtonSignUp(true)}>Sign Up</button>
                                        <SignUp trigger={buttonSignUp} setTrigger={setButtonSignUp} />
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                ) : (

                    <div className="nav-icon-menu">
                        <SignUp trigger={buttonSignUp} setTrigger={setButtonSignUp} />
                        <Signin trigger={buttonSignin} setTrigger={setButtonSignin} />

                        <input type="checkbox" id="drawer-toggle" className="drawer-toggle" checked={isDrawerOpen} onChange={toggleDrawer} />
                        <label htmlFor="drawer-toggle" className="drawer-button">☰</label>
                        <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
                            <nav>
                                <ul>
                                    {/* <li>
                                    <Link to="/">
                                            <button className="navb mobile-nav-ele" onClick={closeDrawer}>Home</button>
                                        </Link>
                                    </li> */}
                                    <li>
                                        <Link to="/events">
                                            <button className="navb mobile-nav-ele" onClick={closeDrawer}>Find Events</button>
                                        </Link>
                                    </li>
                                    {user && user.data ? (
                                        <>
                                            <li>
                                                <Link to="/createEvent">
                                                    <button className="navb mobile-nav-ele" onClick={closeDrawer}>Create Event</button>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/profile">
                                                    <button className="navb mobile-nav-ele" onClick={closeDrawer}>Profile</button>
                                                </Link>
                                            </li>

                                            <li>
                                                <button className="navb mobile-nav-ele" onClick={() => { closeDrawer(); logout(); }}>Log Out</button>
                                            </li>
                                        </>
                                    ) : (
                                        <>
                                            <li>
                                                <button className="navb login mobile-nav-ele" onClick={() => { setButtonSignin(true); closeDrawer(); }}>Log In</button>

                                            </li>
                                            <li>
                                                <button className="navb signup mobile-nav-ele" onClick={() => { setButtonSignUp(true); closeDrawer(); }}>Sign Up</button>

                                            </li>
                                        </>
                                    )}
                                </ul>
                            </nav>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Navbar;


